import React from 'react';
import PropTypes from 'prop-types';
import { Events } from 'api';

import { Alert } from 'components';
import { EXPLORE_SCOPE_OPTIONS } from './helpers';

const formatNumber = (number) => {
  if (number || number === 0) {
    return number;
  }
  return <span className="fa fa-circle-o-notch fa-spin" />;
};

export default class EventsEmpty extends React.PureComponent {
  state = {
    data: null,
  };

  cancelPromise = false;

  static propTypes = {
    changeScope: PropTypes.func.isRequired,
    eventScope: PropTypes.string.isRequired,
    eventsWithoutFilters: PropTypes.number.isRequired,
    removeFilters: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.cancelPromise = false;
    Events.exploreCounts().then((data) => {
      if (this.cancelPromise) {
        return;
      }
      delete data.ended; // We don't display events that have ended
      this.setState({
        data,
      });
    });
  }

  componentWillUnmount() {
    this.cancelPromise = true;
  }

  formatNumberAttr = (attr) => {
    const count = this.state.data && this.state.data[attr];
    return formatNumber(count);
  };

  render() {
    const currentFilter = EXPLORE_SCOPE_OPTIONS.find(
      (filter) => filter.key === this.props.eventScope
    );

    if (this.props.eventsWithoutFilters > 0) {
      return (
        <Alert type="info">
          <p>
            <span className="fa fa-exclamation-triangle icon-space-r" />
            No events under the current search (out of {this.props.eventsWithoutFilters} total{' '}
            {currentFilter.sentence_part} {currentFilter.title.toLowerCase()}
            ).
          </p>
          <button
            type="button"
            className="btn btn-default btn-sm mt1"
            onClick={this.props.removeFilters}
          >
            <span className="fa fa-close icon-space-r" />
            Remove filters
          </button>
        </Alert>
      );
    }

    const totalCount =
      this.state.data && Object.values(this.state.data).reduce((sum, x) => sum + x, 0);
    const otherStatement = totalCount === null || totalCount >= 1 ? 'However, there' : 'There';
    const eventCount =
      totalCount === 1 ? 'is 1 event' : <span>are {formatNumber(totalCount)} events</span>;

    return (
      <Alert type="info">
        <p>
          <span className="fa fa-exclamation-triangle icon-space-r" />
          No events {currentFilter.sentence_part} {currentFilter.title.toLowerCase()}.{' '}
          {otherStatement} {eventCount} under different filters:
        </p>
        {EXPLORE_SCOPE_OPTIONS.filter((filter) => filter.key !== this.props.eventScope).map(
          (filter) => (
            <p key={filter.key}>
              {this.formatNumberAttr(filter.key)}{' '}
              {this.state.data && this.state.data[filter.key] === 1 ? 'event' : 'events'}{' '}
              {filter.sentence_part}{' '}
              <a className="cursor-pointer" onClick={() => this.props.changeScope(filter.key)}>
                {filter.title} <span className="fa fa-angle-right" />
              </a>
            </p>
          )
        )}
      </Alert>
    );
  }
}
