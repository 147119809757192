import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestEventsExplore, syncExploreFilters } from 'store/actions/events/explore';
import { eventExplorePropTypes } from 'helpers/proptypes';

import { EXPLORE_SCOPE_OPTIONS, defaultFilterOptions } from './helpers';
import EventsBase from './EventsBase';
import { EventBlocks } from './EventBlock';
import EventRadioGroup from './EventRadioGroup';
import EventsEmpty from './EventsEmpty';

export class EventsExplore extends Component {
  static propTypes = {
    events: PropTypes.arrayOf(eventExplorePropTypes).isRequired,
    eventsLoading: PropTypes.bool.isRequired,
    eventsErrored: PropTypes.bool.isRequired,
    eventScope: PropTypes.string.isRequired,
    fetchData: PropTypes.func.isRequired,
    filteredEvents: PropTypes.arrayOf(eventExplorePropTypes).isRequired,
    searchTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.object,
    syncFilters: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchData(this.props.eventScope || 'registrationOpen');
  }

  handleRadioChange = (selectedValue) => {
    this.props.fetchData(selectedValue);
  };

  removeFilters = () => {
    this.props.syncFilters({
      events: this.props.events,
      options: defaultFilterOptions,
      searchTerms: [],
    });
    this.eventsBaseRef.selectizeRef.clear();
  };

  setEventsBaseRef = (eventsBase) => {
    this.eventsBaseRef = eventsBase;
  };

  renderEmptyOutlet = () => (
    <EventsEmpty
      eventScope={this.props.eventScope}
      eventsWithoutFilters={this.props.events.length}
      changeScope={this.handleRadioChange}
      removeFilters={this.removeFilters}
    />
  );

  render() {
    const filters = ['day', 'type', 'registration_status', 'strand_type'];
    return (
      <div className="col-xs-12">
        <div className="row">
          <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-below">
            <div className="text-center mb2">
              <h1>Explore</h1>
              <EventRadioGroup
                selectedValue={this.props.eventScope}
                onChange={this.handleRadioChange}
                options={EXPLORE_SCOPE_OPTIONS}
              />
            </div>
          </div>
        </div>
        <EventsBase
          ref={this.setEventsBaseRef}
          Outlet={EventBlocks}
          events={this.props.events}
          isLoading={this.props.eventsLoading}
          hasErrored={this.props.eventsErrored}
          filteredEvents={this.props.filteredEvents}
          options={this.props.options}
          searchTerms={this.props.searchTerms}
          syncFilters={this.props.syncFilters}
          filterClassName="white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above"
          filters={filters}
          EmptyOutlet={this.renderEmptyOutlet}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ eventsExplore }) => {
  return {
    events: eventsExplore.events,
    eventsErrored: eventsExplore.hasErrored,
    eventsLoading: eventsExplore.isLoading,
    eventScope: eventsExplore.eventScope,
    filteredEvents: eventsExplore.filteredEvents,
    options: eventsExplore.options,
    searchTerms: eventsExplore.searchTerms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (filter) => dispatch(requestEventsExplore(filter)),
    syncFilters: (events, options, searchTerms) =>
      dispatch(syncExploreFilters(events, options, searchTerms)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsExplore);
