import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { eventExplorePropTypes } from 'helpers/proptypes';
import { ceuTitle } from './helpers';
import { EventLabels } from 'helpers/dictionaries';
import EventRegistrationStatus from './EventRegistrationStatus';
import { EventCallouts } from './components';
import { Link } from 'react-router-dom';

const ceuClass = (ceuVal) => {
  if (ceuVal <= 0) {
    return 'fa-star-o';
  }
  return 'fa-star';
};

export default class EventBlock extends Component {
  static propTypes = {
    event: eventExplorePropTypes,
  };

  shouldComponentUpdate(nextProps) {
    // Ignore checking for state changes as no state is used
    return (
      nextProps.event.pk !== this.props.event.pk ||
      nextProps.event.last_modified !== this.props.event.last_modified ||
      nextProps.event.registration_status !== this.props.event.registration_status
    );
  }

  render() {
    const times =
      this.props.event.times.length > 0
        ? this.props.event.times
        : [['No schedule at this time', []]];
    const callouts = EventCallouts(this.props.event);

    const { EVENT_TYPES, STRAND_TYPES } = EventLabels;
    return (
      <div
        className={`col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-0 col-md-4 col-md-offset-0 event-block event-block--${
          this.props.event.strand_type
        }`}
      >
        <div className="event-block__header">
          <div className="event-block__strand-icon" />
          <div className="event-block__strand-title">
            {STRAND_TYPES[this.props.event.strand_type]}
          </div>
        </div>
        <div className="event-block__content">
          <div className="event-block__content-top">
            <div className="event-block__type">{EVENT_TYPES[this.props.event.type]}</div>
            <div className="event-block__title">{this.props.event.title}</div>
            {callouts && <div className="event-block__callouts">{callouts}</div>}
          </div>
          <div className="event-block__times">
            {times.map((time, index) => (
              <div className="event-block__line" key={index}>
                <span className="event-block__line-icon fa fa-clock-o fa-fw" />
                {time[0]}
              </div>
            ))}
          </div>
          {!callouts && <div className="event-block__filler" />}
          <div className="event-block__line">
            <span
              className={`event-block__line-icon fa ${ceuClass(this.props.event.ceu_val)} fa-fw`}
            />
            {ceuTitle(this.props.event.ceu_val, this.props.event.ceu_type)}
          </div>
          <div className="event-block__actions">
            <Link to={`/events/${this.props.event.pk}`}>
              <div className="event-block__action btn btn-default">
                More Information
                <span className="event-block__action-icon fa fa-chevron-right" />
              </div>
            </Link>
            <EventRegistrationStatus registrationStatus={this.props.event.registration_status} />
          </div>
        </div>
      </div>
    );
  }
}

export const EventBlocks = ({ events }) => {
  return (
    <div className="row">
      <div className="events__wrapper">
        {events.map((event) => (
          <EventBlock event={event} key={event.pk} />
        ))}
      </div>
    </div>
  );
};

EventBlocks.displayName = 'EventBlocks'; // jest --coverage breaks otherwise
EventBlocks.propTypes = {
  events: PropTypes.arrayOf(eventExplorePropTypes),
};
